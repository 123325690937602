import React from "react";
import "../styles/Setting.css";
import rules from "../assets/rules.png";
import hotkey from "../assets/hotkeys.png";
import stats from "../assets/stats.png";
import wallet from "../assets/wallet.png";
import gameHeading from "../assets/game-heading.png";
import closeSetting from "../assets/close-arrow.png";
import Sound from "../components/Sound";
import dashBoard from "../assets/dashboard.png";
import leaderBoard from "../assets/leaderboard.png";
import termsNcondition from "../assets/terms&condition.png";
import redeemToken from "../assets/redeem-token.png";
import SettingAction from "../components/SettingAction";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Setting = ({
  setWinStatus,
  settingScreen,
  setSettingScreen,
  setDashBoardScreen,
  setLeaderBoardScreen,
  setTermsCondition,
  setRuleScreen,
  setHotkeyScreen,
  setStatsScreen,
  setTransactionScreen,
  setAddTokenScreen,
  setRedeemTokenScreen,
  modeSwitch,
  theme,
  maxWidth700,
  maxHeight700,
  handleRuleScreen,
  handleHotkeys,
  handleStatsScreen,
  handleGameWallet,
  bgMusicRef,
  coinFlipRef,
  coinSound,
  betBtnRef,
  walletAdd,
  audio,
  winSoundRef,
  loseSoundRef,
  toogleSoundRef,
  hotkeyToggle,
  isSoundPause,
  setIsSoundPause,
  platform
}) => {
  const handleDashboard = () => {
    audio.play();
    if (modeSwitch) {
      setSettingScreen(false);
      toast("Switch to Real Mode first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    if (!walletAdd) {
      setSettingScreen(false);
      toast("You must login first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setSettingScreen(false);
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    setLeaderBoardScreen(false);
    setTransactionScreen(false);
    setTermsCondition(false);
    setDashBoardScreen(true);
  };

  const handleLeaderBoard = () => {
    audio.play();
    setSettingScreen(false);
    setDashBoardScreen(false);
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    setTransactionScreen(false);
    setTermsCondition(false);
    setLeaderBoardScreen(true);
  };

  const handleTermsNCondition = () => {
    audio.play();
    setSettingScreen(false);
    setDashBoardScreen(false);
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setAddTokenScreen(false);
    setRedeemTokenScreen(false);
    setTransactionScreen(false);
    setLeaderBoardScreen(false);
    setTermsCondition(true);
  };

  const handleRedeemToken = () => {
    audio.play();
    setSettingScreen(false);
    if (modeSwitch) {
      toast("Switch to Real Mode first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    if (!walletAdd) {
      toast("You must login first!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: !theme ? "dark" : "light",
      });
      return;
    }
    setSettingScreen(false);
    setDashBoardScreen(false);
    setRuleScreen(false);
    setHotkeyScreen(false);
    setStatsScreen(false);
    setLeaderBoardScreen(false);
    setAddTokenScreen(false);
    setTransactionScreen(false);
    setTermsCondition(false);
    setRedeemTokenScreen(true);
  };

  const mediaSettingArray = [
    {
      imageName: wallet,
      lableName: "Wallets",
      onClick: () => handleGameWallet(),
    },
    {
      imageName: rules,
      lableName: "Rules",
      onClick: () => handleRuleScreen(),
    },
    {
      imageName: dashBoard,
      lableName: "Dashboard",
      onClick: () => handleDashboard(),
    },
    {
      imageName: leaderBoard,
      lableName: "leader Board",
      onClick: () => handleLeaderBoard(),
    },
    {
      imageName: termsNcondition,
      lableName: "terms and conditions",
      onClick: () => handleTermsNCondition(),
    },
    {
      imageName: redeemToken,
      lableName: "redeem token",
      onClick: () => handleRedeemToken(),
    },
    {
      imageName: stats,
      lableName: "Stats",
      onClick: () => handleStatsScreen(),
    },
    {
      imageName: hotkey,
      lableName: "HotKeys",
      onClick: () => handleHotkeys(),
    },
  ];

  const settingArray = [
    {
      imageName: dashBoard,
      lableName: "Dashboard",
      onClick: () => handleDashboard(),
    },
    {
      imageName: leaderBoard,
      lableName: "leader Board",
      onClick: () => handleLeaderBoard(),
    },
    {
      imageName: termsNcondition,
      lableName: "terms and conditions",
      onClick: () => handleTermsNCondition(),
    },
    {
      imageName: redeemToken,
      lableName: "redeem token",
      onClick: () => handleRedeemToken(),
    },
  ];
  return (
    <div
      className={
        settingScreen
          ? "setting-container flex visible"
          : "setting-container flex"
      }
    >
      <img
        src={gameHeading}
        alt="risky-coin-flip"
        className="setting-game-heading"
      />
      <div className="setting-horizontal-line"></div>
      <div className="sound-container" style={{marginTop: platform === 'ios' ? "0" : '40px'}}>
        <Sound
          labelName={"music"}
          audioRef={bgMusicRef}
          hotkeyToggle={hotkeyToggle}
          platform = {platform}
          />
        <Sound
          labelName={"SFX"}
          audioRef={betBtnRef}
          soundSrc={coinSound}
          coinRef={coinFlipRef}
          winRef={winSoundRef}
          loseRef={loseSoundRef}
          hotkeyRef={toogleSoundRef}
          hotkeyToggle={hotkeyToggle}
          platform = {platform}
          isSoundPause = {isSoundPause}
          setIsSoundPause = {setIsSoundPause}
          setWinStatus = {setWinStatus}
        />
      </div>

      <div className="setting-action-container flex" style={{marginTop: platform === 'ios' ? "0" : '30px'}}>
        {(maxWidth700 || maxHeight700 ? mediaSettingArray : settingArray).map(
          (item, index) => (
            <div key={index}>
              <SettingAction
                imgSrc={item.imageName}
                labelName={item.lableName}
                onClick={item.onClick}
              />
            </div>
          )
        )}
      </div>

      <div className="copyright-container flex">
        <p className="copyright-subheading">&copy; 2023 Risky Coin Flip</p>
      </div>

      <img
        src={closeSetting}
        alt="close-setting"
        className="setting-close-btn"
        onClick={() => {
          audio.play();
          setSettingScreen(false);
        }}
      />
    </div>
  );
};

export default Setting;
