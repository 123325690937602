
import {encryption} from "../Functions/Encrypted";
import { decryption } from "../Functions/Decrypted";
import axiosBaseURL from "./axiosConfig";

export const loginRegister = async (wallet_address) => {
  try {
    const encryptedWalletAddress = encryption(wallet_address)
    // console.log("Enc wallet Address.......//////",encryptedWalletAddress)
    const result = await axiosBaseURL.post("/register", {
      wallet_address : encryptedWalletAddress,
    });
    return result.data;
  } catch (error) {
    console.log("Error occur login/register api", error);
  }
};

export const addStats = async (token, userStats) => {
  try {
    const encryptedStats = encryption(userStats)
    await axiosBaseURL.post(
      "/Stats",
      {
        encryptedData : encryptedStats,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log("Error occur add stats api",error)
  }
};

export const gameDashboard = async (token, data) => {
  try {
    const encryptedGameDashboardData = encryption(data)
    const result = await axiosBaseURL.post(
      "/Game-Dashboard",
      {
        encryptedData : encryptedGameDashboardData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (result.data?.Encryted_Userflipdone) {
      const decryptResult = decryption(result.data.Encryted_Userflipdone);
      return decryptResult;
    }
    return false;
  } catch (error) {
    console.log("Error occur game dashboard api",error)
  }
};

export const betADA = async (data) => {
  try {
    await axiosBaseURL.post(
      "/Add_Betvalue",
      {
        value : data,
      }
    );
    console.warn("Added")
  } catch (error) {
    console.log("Error occur win streak api",error)
  }
};
