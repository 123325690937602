import React from "react";
import { Chart } from "react-google-charts";

export default function StatsChart({userStats}) {
   const data = [
    ["Task", "Hours per day"],
    ["Losses", parseInt(userStats.losess)],
    ["Wins", parseInt(userStats.win)],
    ["Profit", Number(userStats.profit)],
  ];
   const options = {
    pieHole: 0.5,
    legend: 'none',
    colors: ["#50C9FF", "#0342B1", "#CD21D8"],
    backgroundColor: 'transparent',
  };
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}