import React, {useEffect} from 'react'
import '../styles/Stats.css'
import Close from '../components/Close'
import StatsChart from '../charts/StatsChart'

const Stats = ({setStatsScreen, userStats,statsEffect, setStatsEffect,audio}) => {
  useEffect(()=>{
    if(statsEffect){
      return;
    }
    setTimeout(()=>{
      setStatsScreen(false)
    },800)
  },[statsEffect])
  return (
    <div className='stats-screen-container flex' style={{animation: statsEffect ? 'fadeIn 1s ease-in-out' : 'fadeOut 1s ease-in-out'}}>
        <h1 className="stats-heading">stats</h1>

        <div className="stats-subheading-bg flex">
            <div className="stats-subheading-box flex">
            <div className="stats-subheading">profit</div>
            <div className="stats-vertical-line"></div>
            <div className="stats-subheading">wins</div>
            <div className="stats-vertical-line"></div>
            <div className="stats-subheading">losses</div>
            </div>
        </div>

        <div className="stats-box flex">
          <div className="stats-heading-box flex">
            <p className='waged-heading'>wagered</p>
            <div className="stats-headings flex">
              {/* profit */}
              <div className="stats-data-heading flex">
                <div className="heading-color"></div>
                <p className='heading-txt'>profit</p>
              </div>
              {/* losses */}
              <div className="stats-data-heading flex">
                <div className="heading-color" style={{backgroundColor: '#64CFF6'}}></div>
                <p className='heading-txt'>losses</p>
              </div>
              {/* wins */}
              <div className="stats-data-heading win-data-heading flex" >
                <div className="heading-color" style={{backgroundColor: '#0342B1'}}></div>
                <p className='heading-txt'>wins</p>
              </div>
            </div>
          </div>
          <div className='stats-chart'>
          {
            !(userStats.win && userStats.profit) && !userStats.losess? <h3 className='no-stats-found'>No stats found</h3> : <StatsChart userStats = {userStats}/>
          }
          </div>
        </div>

        {/* /////// Close Btn //////// */}
        <Close onClick={() => {audio.play();setStatsEffect(false)}}/>
    </div>
  )
}

export default Stats