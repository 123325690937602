import React, { useEffect, useRef, useState } from "react";
import gameLogo from "../assets/game-logo.png";
import darkSplashBg from "../assets/splash-bg.png";
import darkTabletBg from "../assets/tablet-bg.png";
import playBtn from "../assets/play-btn.png";
import lightSplashBg from "../assets/whiteTheme/white-bg.png";
import "../styles/Splash.css";
import FunMode from "./FunMode";
import RealMode from "./RealMode";
import { useMediaQuery } from "react-responsive";
import bgMusic from "../assets/audio/bg-sound.mp3";
import betBtnSound from "../assets/audio/bet-button-sound.mp3";
import toogleSound from "../assets/audio/toggle-sound.mp3";
import { advertisingImageFromServer } from "../APIs/GetAPI";

const Splash = () => {
  const maxWidth700 = useMediaQuery({ query: "(max-width: 700px)" });
  const [time, setTime] = useState(false);

  // audio Ref
  const bgMusicRef = useRef(null);
  const coinFlipRef = useRef(null);
  const betBtnRef = useRef(null);
  const toogleSoundRef = useRef(null);
  const winSoundRef = useRef(null);
  const loseSoundRef = useRef(null);

  // mode = true -> funMode
  // mode = false -> RealMode
  const [modeToggle, setModeToggle] = useState(true);

  const [modeRenderEffect, setModeRenderEffect] = useState(true);
  const [modeSwitch, setModeSwitch] = useState(true);

  // theme = true -> light
  // theme = false -> dark
  const [theme, setTheme] = useState(true);
  // Network
  const [internetStatus, setInternetStatus] = useState(navigator.onLine);
  const [advertisingImage, setAdvertisingImage] = useState({});

  // Check internet connectivity
  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      // console.log("handleStatusChange", navigator.onLine);
      setInternetStatus(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, []);

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem("theme"));
    setTheme(value);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setModeRenderEffect(modeToggle);
    }, 300);
    setTimeout(() => {
      setModeSwitch(modeToggle);
    }, 800);
  }, [modeToggle]);

  const handlePlayButton = () => {
    betBtnRef.current.play();
    bgMusicRef.current.play();
    setTime(true);
  };

  useEffect(() => {
    const image = document.getElementById("splash-image-prevent");
    image.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

   // get advertisingImage from the server
   useEffect(() => {
    const getAdvertisingImageFromServer = async () => {
      const adsObj = await advertisingImageFromServer();
      if(adsObj){
        setAdvertisingImage({
          image : adsObj.image,
          link : adsObj.link,
        });
      }else{
        setAdvertisingImage(adsObj)
      } 
    };
    getAdvertisingImageFromServer()
  }, []);

  return (
    <>
      {!time && modeToggle ? (
        <div
          className="splash-bg"
          style={{
            backgroundImage: !theme
              ? `${
                  maxWidth700 ? `url(${darkTabletBg})` : `url(${darkSplashBg})`
                }`
              : `url(${lightSplashBg})`,
          }}
          id="splash-image-prevent"
        >
          <img src={gameLogo} alt="game-logo" className="splash-screen-logo" />
          <img
            src={playBtn}
            alt="play-logo"
            className="splash-play-logo"
            onClick={() => handlePlayButton()}
          />
        </div>
      ) : modeSwitch ? (
        <FunMode
          setModeToggle={setModeToggle}
          modeToggle={modeToggle}
          setTheme={setTheme}
          theme={theme}
          modeRenderEffect={modeRenderEffect}
          modeSwitch={modeSwitch}
          bgMusicRef={bgMusicRef}
          coinFlipRef={coinFlipRef}
          betBtnRef={betBtnRef}
          toogleSoundRef={toogleSoundRef}
          winSoundRef={winSoundRef}
          loseSoundRef={loseSoundRef}
          internetStatus={internetStatus}
          advertisingImage = {advertisingImage}
        />
      ) : (
        <RealMode
          setModeToggle={setModeToggle}
          modeToggle={modeToggle}
          setTheme={setTheme}
          theme={theme}
          modeRenderEffect={modeRenderEffect}
          modeSwitch={modeSwitch}
          bgMusicRef={bgMusicRef}
          coinFlipRef={coinFlipRef}
          betBtnRef={betBtnRef}
          toogleSoundRef={toogleSoundRef}
          winSoundRef={winSoundRef}
          loseSoundRef={loseSoundRef}
          internetStatus={internetStatus}
          advertisingImage = {advertisingImage}
        />
      )}

      <audio src={bgMusic} ref={bgMusicRef} loop />
      <audio src={betBtnSound} ref={betBtnRef} />
      <audio src={toogleSound} ref={toogleSoundRef} />
    </>
  );
};

export default Splash;
