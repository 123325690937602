import React from "react";

const SettingAction = ({imgSrc , labelName, onClick}) => {
  return (
    <div className="action-box flex" onClick={onClick}>
      <img src={imgSrc} alt="dashboard" className="action-box-img" />
      <span className="action-lable-name">{labelName}</span>
    </div>
  );
};

export default SettingAction;
