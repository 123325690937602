import React from "react";

const Key = ({keyCap, keyValue}) => {
  return (
    <div className="key-box flex">
      <div className="key-cap flex">{keyCap}</div>
      <div className="key-value">{keyValue}</div>
    </div>
  );
};

export default Key;
