import React from 'react'

const TransactionHistory = ({id, date, amt, type,status}) => {
  const Type = Number(type);
  // console.log("status",status);
  return (
    <div className="wallet-transaction-bg flex">
          <div className="wallet-subheading-data-box flex">
            <div className="wallet-subheading-data hash-id">{id ? id?.slice(0,4) + '...' + id?.slice(id?.length - 4) : "---"}</div>
            <div className='flex wallet-date-wrapper'>
            <div className="leaderboard-vertical-line"></div>
            <div className="wallet-subheading-data">{date.split('T')[0].replaceAll('-','/')}</div>
            <div className="leaderboard-vertical-line"></div>
            </div>
            <div className="wallet-subheading-data wallet-amount-value" style={{color : !Type ? '#0fd40f' : '#e70c0c'}}>{!Type ? '+' + parseInt(amt).toFixed(2) : '-' + parseInt(amt).toFixed(2)}</div>
            <div className="leaderboard-vertical-line"></div>
            <div className="wallet-subheading-data">{status === "2" ? <span style={{color : 'rgb(235 180 18)'}}>Pending</span> : status === "3" ? <span style={{color : 'red'}}>Failed</span> : <span style={{color : 'rgb(14 243 13)'}}>Success</span>}</div>
          </div>
    </div>
  )
}

export default TransactionHistory