import React from "react";
import ReactDOM from "react-dom/client";
import App from "./screens/App";
// import reportWebVitals from './reportWebVitals';

// Media Queries
import "./styles/MediaWidth.css";
import "./styles/MediaHeight.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // {/* </React.StrictMode> */}
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
