import React, { useEffect, useState } from "react";
import "../styles/App.css";
import Splash from "./Splash";
import UnderMaintanance from "./UnderMaintanance";
import { getInMaintenance } from "../APIs/GetAPI";

const App = () => {
  const [inmaintenance, setinmaintenance] = useState(false);
  /**
   * API FOR UNDER MAINTENANCE
   */
  useEffect(() => {
    (async function () {
      const inMaintenance = await getInMaintenance();
      setinmaintenance(inMaintenance);
    })();
  }, []);

  return <> {inmaintenance ? <UnderMaintanance /> : <Splash />}</>;
};

export default App;
