import React, { useState } from "react";

const BetBtn = ({
  imgSrc,
  onClick,
  btnDisable,
  btnValue,
  theme,
  secondImg,
}) => {
  const [btnScaling, setBtnScaling] = useState(false);

  // handleMouseDown
  const handleMouseDown = () => {
    setBtnScaling(true);
  };

  // handleMouseUp
  const handleMouseUp = () => {
    setBtnScaling(false);
  };

  // handleMouseMove
  const handleMouseMove = () => {
    setBtnScaling(false);
  };
  return (
    <button
      disabled={btnDisable}
      className="bet-btn"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      style={{ transform: btnScaling ? "scale(0.9)" : "scale(1)" }}
      draggable={false}
      onClick={onClick}
    >
      <img
        src={imgSrc}
        alt="bet-head"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
          position: "relative",
        }}
      />
      {theme && (
        <div className="light-theme-buttons flex">
          <img src={secondImg} alt="coin" className="bet-coin-image" />
          <p className={btnValue === 'bet head' ?  'light-bet-btn light-head-txt' : 'light-bet-btn'}>{btnValue}</p>
        </div>
      )}
    </button>
  );
};

export default BetBtn;
