import React, { useEffect, useState } from "react";
import "../styles/Leaderboard.css";
import LeaderboardUser from "../components/LeaderboardUser";
import Close from "../components/Close";
import { leaderBoard } from "../APIs/GetAPI";

const Leaderboard = ({ setSettingScreen, setLeaderBoardScreen, theme,audio }) => {
  const [leaderboardProfile, setLeaderboardProfile] = useState(null);
  const [leaderboardEffect, setLeaderboardEffect] = useState(true);

  useEffect(() => {
    if (leaderboardEffect) {
      return;
    }
    setTimeout(() => {
      setLeaderBoardScreen(false);
      setSettingScreen(true);
    }, 800);
  }, [leaderboardEffect]);

  const handleClose = () => {
    audio.play();
    setLeaderboardEffect(false);
  };

  const getLeaderBoardData = async () => {
    const result = await leaderBoard();
    if (result) {
      setLeaderboardProfile(JSON.parse(result));
    }
  };

  useEffect(() => {
    getLeaderBoardData();
  }, []);

  return (
    <div
      className="leaderboard-container flex"
      style={{
        animation: leaderboardEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <h1 className="leaderboard-heading">leaderboard</h1>
      <div className="leaderboard-subheading-bg flex">
        <div className="leaderboard-subheading-box flex">
          <div className="leaderboard-subheading">Rank</div>
          <div className="leaderboard-vertical-line"></div>
          <div className="leaderboard-subheading">wallet id</div>
          <div className="leaderboard-vertical-line"></div>
          <div className="leaderboard-subheading">profit</div>
        </div>
      </div>

      <div className="leaderboard-users-container flex">
        {leaderboardProfile &&
          leaderboardProfile.map((item, index) => {
            if(Number(item.profit) === 0){
              return null
            }
            return (
              <div
                key={index}
                className="leadboard-user-bg-black flex"
                style={{ boxShadow: theme && "none" }}
              >
                <LeaderboardUser
                  rank={item.rank}
                  walletId={item.wallet_address}
                  profitEarn={item.profit}
                />
              </div>
            );
          })}
      </div>

      <Close onClick={handleClose} />
    </div>
  );
};

export default Leaderboard;
