import { encryptionObj } from "../../Functions/Encrypted";
import axiosBaseURL from "../axiosConfig";

export const addAmount = (userToken, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axiosBaseURL({
        url: "/Add_Amount",
        method: "post",
        data: body,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const addToken = async (token, data) => {
  try {
    const result = await axiosBaseURL.post("/Add-token", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("result.data.........", result.data);
    if (result.data.Success) {
      return result.data.Success;
    }
    return false;
  } catch (error) {
    console.log("Error occur add token api", error);
  }
};

export const redeemToken = async (token, data) => {
  try {
    const encryptedRedeemTokenData = encryptionObj(data);
    const result = await axiosBaseURL.post(
      "/redeem-token",
      {
        encryptedData: encryptedRedeemTokenData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        timeout: 300000, // Timeout set to 5 minutes (300,000 milliseconds)
      }
    );

    return result.data;
  } catch (error) {
    console.log("Error occur redeem token api", error);
  }
};

export const redeemTokenRequest = async (token, data) => {
  try {
    // const encryptedRedeemTokenData = encryptionObj(data);
    const result = await axiosBaseURL.post("/redeem_request", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: 300000, // Timeout set to 5 minutes (300,000 milliseconds)
    });
    return result.data;
  } catch (error) {
    console.log("Error occur redeem token api", error);
  }
};

// deduct bet amount
export const deductBet = async (token, data) => {
  try {
    const encryptedSubractData = encryptionObj({
      count: data,
    });
    const result = await axiosBaseURL.post(
      "/subtractbettoken",
      {
        encryptedData: encryptedSubractData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("Result", result.data);
    return result.data;
  } catch (error) {
    console.log("Error occur subract token api", error);
  }
};

// add win amount api
export const addWinToken = async (token, data) => {
  try {
    const encryptedAddWinToken = encryptionObj({
      count: data,
    });
    // console.log("EncryptedAdd Data.........../////////",encryptedAddWinToken)
    const encryptResult = await axiosBaseURL.post(
      "/addbettoken",
      {
        encryptedData: encryptedAddWinToken,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return encryptResult.data;
  } catch (error) {
    console.log("Error occur add bet token", error.response);
    return [];
  }
};
