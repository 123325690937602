import React from "react";
import maintenanceImage from "../assets/maintanence.png";
function UnderMaintanance() {
  return (
    <div className="under-maintance">
      <div className="card">
        <h1 className="card-heading">maintenance Break</h1>
        <div className="image" style={{marginTop : '1rem'}}>
          <img src={maintenanceImage} alt="maintenance_img" width="50%" />
        </div>
        <p className="card-text">
          We'll be right back! Sorry for the maintaining delay in your work. We
          are just temporarily down due to regular maintenance. We will be back
          up again soon!
        </p>
      </div>
    </div>
  );
}

export default UnderMaintanance;
