import React, { useEffect } from "react";
import darkKeyboard from "../assets/dark-keyboard.png";
import lightKeyboard from '../assets/whiteTheme/light-keyboard.png'
import rightArrow from "../assets/gameMode.png";
import "../styles/HotKeys.css";
import Key from "../components/Key";
import Close from "../components/Close";

const HotKeys = ({
  hotkeyToggle,
  setHotkeyToggle,
  setHotkeyScreen,
  setHotKeyEffect,
  hotkeyEffect,
  theme,
  audio,
  currentToggle
}) => {
  useEffect(() => {
    if (hotkeyEffect) {
      return;
    }
    setTimeout(() => {
      setHotkeyScreen(false);
    }, 800);
  }, [hotkeyEffect]);

  const handleHotKeysToggle = () => {
    currentToggle.play()
    if (hotkeyToggle) {
      setHotkeyToggle(false);
      localStorage.setItem("hotKeyEnable", false);
    } else {
      setHotkeyToggle(true);
      localStorage.setItem("hotKeyEnable", true);
    }
  };

  return (
    <div
      className="game-hotkey-box flex"
      style={{
        animation: hotkeyEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <h1 className="hotkey-heading">Hotkeys</h1>
      <img
        src={!theme ? darkKeyboard : lightKeyboard}
        alt="hotkey-keyboard"
        className="hotkey-keyboard"
      />

      <div className="keys-container flex">
        <Key keyCap={"q"} keyValue={"Bet Head"} />
        <Key keyCap={"w"} keyValue={"Bet Tail"} />
        <Key keyCap={"a"} keyValue={"Min Bet"} />
        <Key keyCap={"s"} keyValue={"Reduce Bet by Half"} />
        <Key keyCap={"d"} keyValue={"Double Bet"} />
        <Key keyCap={"b"} keyValue={"Max Bet"} />
        <Key keyCap={"m"} keyValue={"Mute"} />
      </div>

      <div className="hotkey-toggle-box flex">
        <p className="hokey-sub-heading">Hotkeys :</p>

        <div
          className="toggle-btn hotkey-toggle"
          style={{
            border: !hotkeyToggle
              ? "1.5px solid #3F0000"
              : "0.621px solid #103F00",
            boxShadow: !hotkeyToggle
              ? "box-shadow: 0px 2.68884px 2.01663px 0px rgba(0, 0, 0, 0.15) inset"
              : "0px 2.68884px 2.01663px 0px rgba(0, 0, 0, 0.15) inset",
          }}
          onClick={() => handleHotKeysToggle()}
          // data-tooltip-id={modeSwitch ? "real-mode" : "fun-mode"}
        >
          <button
            className="btn-circle flex"
            style={{
              left: !hotkeyToggle ? "-3px " : "51%",
              background: !hotkeyToggle
                ? `linear-gradient(0deg, #58F621 0%, #BA0505 0.01%, #FF6767 100%)`
                : "linear-gradient(0deg, #21A9F6 0%, #1EBA05 0.01%, #85FF67 100%)",
              border: !hotkeyToggle
                ? `2px solid #620505`
                : `1.008px solid #126205`,
            }}
          >
            {!hotkeyToggle ? (
              <>
                <div className="horizontal-line"></div>
                <div className="horizontal-line second"></div>
              </>
            ) : (
              <img src={rightArrow} alt="real-mode" className="right-arrow" />
            )}
          </button>
        </div>
      </div>

      <Close onClick={() => {audio.play(); setHotKeyEffect(false)}} />
    </div>
  );
};

export default HotKeys;
