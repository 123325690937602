import CryptoJS from "crypto-js";

export function encryption(data){
    const secretKey = "c16d5adecfd9614d8bd4bf598b9e778a";
    const iv = "1b2d6c6e1aee25376413da6e0107f8a5";
    // Encrypt data
    const ciphertext = CryptoJS.AES.encrypt(data, secretKey, {
      iv,
    }).toString();
    return ciphertext;
  };

export function encryptionObj(data){
    const secretKey = "c16d5adecfd9614d8bd4bf598b9e778a";
    const iv = "1b2d6c6e1aee25376413da6e0107f8a5";
    // Encrypt data
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey, {
      iv,
    }).toString();
    return ciphertext;
  };