import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

const Sound = ({
  labelName,
  audioRef,
  soundSrc,
  coinRef,
  winRef,
  loseRef,
  hotkeyRef,
  hotkeyToggle,
  platform,
  isSoundPause,
  setIsSoundPause,
  setWinStatus,
}) => {
  const [soundValue, setSoundValue] = useState(0.7);

  useEffect(() => {
    if (soundValue > 0) {
      audioRef.current.volume = soundValue;
      if (labelName === "SFX") {
        coinRef.current.volume = soundValue;
        winRef.current.volume = soundValue;
        loseRef.current.volume = soundValue;
        hotkeyRef.current.volume = soundValue;
      }
    } else if (soundValue <= 0) {
      audioRef.current.volume = 0;
      if (labelName === "SFX") {
        coinRef.current.volume = soundValue;
        winRef.current.volume = soundValue;
        loseRef.current.volume = soundValue;
        hotkeyRef.current.volume = soundValue;
      }
    }

    // const audioContext = new AudioContext();
    // const audioElement = audioRef.current;
    // const audioSource = audioContext.createMediaElementSource(audioElement);
    // const gainNode = audioContext.createGain(); // Create a GainNode for controlling volume
    // audioSource.connect(gainNode);
    // gainNode.connect(audioContext.destination);
    // gainNode.gain.value = soundValue; // Set the initial volume
  }, [soundValue]);

  useEffect(() => {
    const volume = JSON.parse(localStorage.getItem(`${labelName}Sound`));
    if (volume === null) {
      return;
    }
    setSoundValue(volume);
  }, []);

  // handle sound volume
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setSoundValue(newVolume);
    localStorage.setItem(`${labelName}Sound`, JSON.stringify(newVolume));
  };

  // Mute sound using hotkey
  const handleHoteKeyMute = () => {
    setSoundValue(0);
    localStorage.setItem(`${labelName}Sound`, JSON.stringify(0));
  };

  useHotkeys("m", handleHoteKeyMute, {
    enabled: hotkeyToggle,
  });

  // when tab change sound is pause and play
  useEffect(() => {
    // Your existing useEffect logic
    const handleVisibilityChange = () => {
      if (document.hidden || document.webkitHidden) {
        audioRef.current.pause();
      } else {
        if (labelName !== "SFX") {
          audioRef.current.play();
        } else {
          if (isSoundPause === "win") {
            setWinStatus(true);
            setTimeout(() => {
              winRef.current.play();
            }, 800);
            setIsSoundPause(null);
            setTimeout(() => {
              setWinStatus(null);
            }, 2500);
          } else if (isSoundPause === "lose") {
            setWinStatus(false);
            setTimeout(() => {
              loseRef.current.play();
            }, 800);
            setIsSoundPause(null);
            setTimeout(() => {
              setWinStatus(null);
            }, 2500);
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [isSoundPause]);

  return (
    <div className="sound-box flex">
      {platform !== "ios" && (
        <>
          <label htmlFor="music" className="sound-heading">
            {labelName}
          </label>
          <div className="sound-level-bar">
            <div
              className="sound-level-bg"
              style={{ width: `${soundValue * 100}%` }}
            ></div>
            <input
              type="range"
              name="music"
              className="sound-bar"
              min="0"
              max="1"
              step="0.01"
              value={soundValue}
              onChange={handleVolumeChange}
            />
          </div>
        </>
      )}
      {labelName === "SFX" && (
        <>
          <audio ref={coinRef} src={soundSrc} />
        </>
      )}
    </div>
  );
};

export default Sound;
