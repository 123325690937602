import React from "react";

const DashBoardStats = ({ bgcolor, statValue, statName }) => {
  return (
    <div className="flex">
      <div
        className="dashboard-stat-color-box"
        style={{ backgroundColor: bgcolor }}
      ></div>
      <div className="dashboard-stat-value">{statValue.toString().padStart(2, '0')} :</div>
      <div className="dashboard-stat-name">&nbsp;{statName}</div>
    </div>
  );
};

export default DashBoardStats;
