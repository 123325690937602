import React, { useEffect, useState } from "react";
import "../styles/Rules.css";
import Close from "../components/Close";

const Rules = ({ setRuleScreen, ruleEffect, setRuleEffect,audio }) => {
  useEffect(() => {
    if (ruleEffect) {
      return;
    }
    setTimeout(() => {
      setRuleScreen(false);
    }, 800);
  }, [ruleEffect]);

  const handleClose = () => {
    audio.play(); 
    setRuleEffect(false)
  }
  return (
    <div
      className="game-rule-box"
      style={{
        animation: ruleEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <h1 className="description-heading">GAME DESCRIPTION</h1>
      <p className="description-txt">
        Flip the coin and bet on this extraordinary game called Heads or Tails.
        This arcade game has a sci-fi theme and it’s all about luck. Heads or
        Tail is extremely simple to play. Thus, all you have to do is choose
        whether you want to play with one coin or with 2. Next, you have a bet
        box from which you can choose how much you want to wager. Next, you need
        to choose between heads or tail. If you have chosen to play with 2
        coins, you will have to choose the heads and tail for each coin.
        Finally, you have to decide on how many times you want the coin to be
        flipped. You can choose from 1, 2 or 3 times. The coins will be flipped
        and if the symbol on the coin is the same as the symbol you’ve chose,
        you win.
        <br />
        <br />
        For one coin, the win multiplies by 2. For two coins, the win multiplies
        by 4. Also, depending on the number of flips, your win can be multiplied
        by up to 15. So, the more flips and coins you choose, the bigger the
        prize. So, try this exciting game when you’re bored and want to try
        something new.
      </p>
      <h1 className="rule-heading">Rules</h1>
      <p className="rule-txt">
        Choose number of coins, then for each coin choose Heads or Tails, and
        then choose how many flips you want.
      </p>

      <Close onClick={() => {handleClose()}} />
    </div>
  );
};

export default Rules;
