import React, { useCallback, useEffect, useState } from "react";
import "../styles/AddToken.css";
import addTokenBtn from "../assets/add-token-btn.png";
import TokenBox from "../components/TokenBox";
import { TransactionUnspentOutput } from "@emurgo/cardano-serialization-lib-asmjs";
// import { TransactionUnspentOutput } from "@emurgo/cardano-serialization-lib-browser";
import { Buffer } from "buffer";
import { getCommunityWalletAddress } from "../APIs/Transactions/GetAPI";

const AddToken = ({
  setSettingScreen,
  setAddTokenScreen,
  API,
  walletAdd,
  userToken,
  theme,
  setCreditPoints,
  audio,
  Utxos,
  setUtxos,
  setDisable,
  disable,
  // setTransactionMessage,
  getTokenAPI,
  activeWalletName,
}) => {
  let utxosCount = 0;
  const [tokenEffect, setTokenEffect] = useState(true);
  const [transactionMessage, setTransactionMessage] = useState(null);
  const [rawUtxoState, setRawUtxoState] = useState("");
  const [clickOnAddButton, setClickonAddButton] = useState(true);
  const [communityWalletAdd, setCommunityWalletAdd] = useState(null);

  // get wallet address from admin panel
  useEffect(() => {
    const getAdminWalletAdd = async () => {
      const addressOfAdmin = await getCommunityWalletAddress();
      setCommunityWalletAdd(addressOfAdmin);
    };
    getAdminWalletAdd();
  }, []);

  // GetUtxos
  const getUtxos = useCallback(async () => {
    // debugger;
    try {
      let utxos = [];
      const rawUtxos = await API.getUtxos();
      if (rawUtxos.length === 0 && walletAdd) {
        console.log("length...///", rawUtxos.length);
        setClickonAddButton(false);
        return;
      }
      // console.log("RawUtxos...///", rawUtxos);

      if (utxosCount < 3) {
        // when length 1
        if (rawUtxos.length === 1) {
          if (
            rawUtxoState === rawUtxos[0] &&
            transactionMessage !== "User declined to sign the transaction" &&
            transactionMessage !== "Balance Insufficient" &&
            transactionMessage !==
              "Please switch the appropriate network for doing the transactions"
          ) {
            utxosCount++;
            getUtxos();
            return;
          }
        }
      }

      if (utxosCount < 3) {
        // when length is greater than 1
        if (rawUtxos.length > 1) {
          if (
            rawUtxoState === rawUtxos[rawUtxos.length - 1] &&
            transactionMessage !== "User declined to sign the transaction" &&
            transactionMessage !== "Balance Insufficient" &&
            transactionMessage !==
              "Please switch the appropriate network for doing the transactions"
          ) {
            utxosCount++;
            getUtxos();
            return;
          }
        }
      }

      if (rawUtxos.length > 1) {
        setRawUtxoState(rawUtxos[rawUtxos.length - 1]);
      } else {
        setRawUtxoState(rawUtxos[0]);
      }

      setClickonAddButton(false);
      setTransactionMessage("");

      for (const rawUtxo of rawUtxos) {
        const utxo = TransactionUnspentOutput.from_bytes(
          Buffer.from(rawUtxo, "hex")
        );
        const input = utxo.input();
        const txid = Buffer.from(
          input.transaction_id().to_bytes(),
          "utf8"
        ).toString("hex");
        const txindx = input.index();
        const output = utxo.output();

        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();

        let multiAssetStr = "";

        if (multiasset) {
          const keys = multiasset.keys();
          const N = keys.len();

          for (let i = 0; i < N; i++) {
            const policyId = keys.get(i);
            const policyIdHex = Buffer.from(
              policyId.to_bytes(),
              "utf8"
            ).toString("hex");

            const assets = multiasset.get(policyId);
            const assetNames = assets.keys();
            const K = assetNames.len();

            for (let j = 0; j < K; j++) {
              const assetName = assetNames.get(j);
              const assetNameString = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString();
              const assetNameHex = Buffer.from(
                assetName.name(),
                "utf8"
              ).toString("hex");
              const multiassetAmt = multiasset.get_asset(policyId, assetName);
              multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`;
            }
          }
        }

        const obj = {
          txid: txid,
          txindx: txindx,
          amount: amount,
          str: `${txid} #${txindx} = ${amount}`,
          multiAssetStr: multiAssetStr,
          TransactionUnspentOutput: utxo,
        };

        // if (+amount < utxo.__wbg_ptr) {
        //   console.log("THIS IS TRIGGERING");
        //   console.log(obj);
        //   console.log(utxo.__wbg_ptr);
        //   console.log(amount);
        //   return;
        // }

        utxos.push(obj);

        // console.log(obj);
        // console.log(utxo.__wbg_ptr);
        // console.log(amount);
      }

      setUtxos(utxos);
    } catch (err) {
      console.log("Error in getUtxos...//", err);
    }
  }, [API, rawUtxoState, setUtxos, transactionMessage, utxosCount, walletAdd]);

  useEffect(() => {
    // if(Utxos.length){
    //   console.log("Utxos.length is greater than 1")
    // }
    if (userToken && walletAdd) {
      setClickonAddButton(false);
      // console.log("raw array", Utxos);
      getUtxos();
    }
  }, [getUtxos, userToken, walletAdd]);

  useEffect(() => {
    if (tokenEffect) {
      return;
    }
    let timeout = setTimeout(() => {
      setAddTokenScreen(false);
      setSettingScreen(false);
    }, 800);
    return () => clearTimeout(timeout);
  }, [setAddTokenScreen, setSettingScreen, tokenEffect]);

  const handleClose = () => {
    audio.play();
    setTokenEffect(false);
  };

  return (
    <div
      className="addToken-container flex"
      style={{
        animation: tokenEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <TokenBox
        heading={"Add token"}
        btnSrc={addTokenBtn}
        onClick={handleClose}
        API={API}
        walletAdd={walletAdd}
        userToken={userToken}
        theme={theme}
        setCreditPoints={setCreditPoints}
        audio={audio}
        Utxos={Utxos}
        // setUtxos={setUtxos}
        getUtxos={getUtxos}
        setDisable={setDisable}
        disable={disable}
        setTransactionMessage={setTransactionMessage}
        getTokenAPI={getTokenAPI}
        activeWalletName={activeWalletName}
        clickOnAddButton={clickOnAddButton}
        setClickonAddButton={setClickonAddButton}
        communityWalletAdd={communityWalletAdd}
      />
    </div>
  );
};

export default AddToken;
