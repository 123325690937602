import Axios from "axios";

const axiosBaseURL = Axios.create({
  // baseURL: "http://45.79.126.10:3028/api" /* Abhiwan server */,
  // baseURL: "http://3.21.241.90:5000/api" /* Abhiwan server */,
  // baseURL: "https://riskycoinflip.com/testnet" /* client server testnet */,
  // baseURL: "http://localhost:5000/api" /* client server mainnet */,
  baseURL: "https://coinapi.riskycoinflip.com/api" /* client server mainnet */,
});

const URL = "https://coinapi.riskycoinflip.com";
// const URL = "http://localhost:5000";
// const URL = "http://45.79.126.10:3028";
// const URL = "http://3.21.241.90:5000";

export { URL };
export default axiosBaseURL;
