import React, { useEffect, useState } from "react";
import redeemTokenBtn from "../assets/redeem-token-btn.png";
import TokenBox from "../components/TokenBox";

const RedeemToken = ({
  setSettingScreen,
  setRedeemTokenScreen,
  creditPoints,
  theme,
  walletAdd,
  userToken,
  audio,
  setDisable,
  disable,
  getTokenAPI,
  activeWalletName,
  API,
}) => {
  const [tokenEffect, setTokenEffect] = useState(true);

  useEffect(() => {
    if (tokenEffect) {
      return;
    }
    setTimeout(() => {
      setRedeemTokenScreen(false);
      setSettingScreen(true);
    }, 800);
    // eslint-disable-next-line
  }, [tokenEffect]);

  const handleClose = () => {
    audio.play();
    setTokenEffect(false);
  };
  return (
    <div
      className="redeemToken-container flex"
      style={{
        animation: tokenEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <TokenBox
        heading={"Redeem token"}
        btnSrc={redeemTokenBtn}
        onClick={handleClose}
        creditPoints={creditPoints}
        theme={theme}
        walletAdd={walletAdd}
        userToken={userToken}
        audio={audio}
        setDisable={setDisable}
        disable={disable}
        getTokenAPI = {getTokenAPI}
        activeWalletName = {activeWalletName}
        API={API}
      />
    </div>
  );
};

export default RedeemToken;
