import React from 'react'

const LeaderboardUser = ({rank, walletId, profitEarn}) => {
  return (
    <div className="leaderboard-user-bg flex">
          <div className="leaderboard-subheading-data-box flex">
            <div className="leaderboard-subheading-data">{rank.toString().padStart(2, '0')}</div>
            <div className='flex add-wrapper'>
            <div className="leaderboard-vertical-line"></div>
            <div className="leaderboard-subheading-data">{walletId.slice(0, 5) +
                    "..." +
                    walletId.slice(walletId.length - 4, walletId.length)}</div>
            <div className="leaderboard-vertical-line"></div>
            </div>
            <div className="leaderboard-subheading-data">+{Number(profitEarn).toFixed(2)}</div>
          </div>
        </div>
  )
}

export default LeaderboardUser