import axios from "axios";

// blockfoast api
export const fetchTransactionDetails = async (hash) => {
  try {
    const response = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/txs/${hash}`,
      // `https://cardano-preprod.blockfrost.io/api/v0/txs/${hash}`,
      {
        headers: {
          project_id: "mainnetPgr8mRng9chjaWsXlxwRCP98J1ksGbQh", // mainnet blockfrost project id
          // project_id: "preprodvSvx4x6tY5C3YvbMcVWr89ZxiIJLWnFv", // test blockfrost project id
        },
      }
    );
    if (response.status === 200) {
      return true;
    }
    // return false
  } catch (error) {
    if ("Request failed with status code 402" === error.message) {
      return "Payment required";
    }
    return false;
  }
};

export const fetchedLovelace = async (walledAdd) => {
  try {
    const response = await axios.get(
      `https://cardano-mainnet.blockfrost.io/api/v0/addresses/${walledAdd}/utxos`,
      // `https://cardano-preprod.blockfrost.io/api/v0/addresses/${walledAdd}/utxos`,
      {
        headers: {
          project_id: "mainnetPgr8mRng9chjaWsXlxwRCP98J1ksGbQh", // mainnet blockfrost project id
          // project_id: "preprodvSvx4x6tY5C3YvbMcVWr89ZxiIJLWnFv", // test blockfrost project id
        },
      }
    );
    if (response.status === 200) {
      console.log(response);
      return response.data.length > 0 ? true : false;
    }
  } catch (error) {
    console.log("fethc lovelave error ***", error);
  }
};
