import React from 'react'

const Close = ({onClick,disable}) => {
  return (
    <button className="close" onClick={onClick} disabled = {disable}>
        <div className="cross"></div>
      </button>
  )
}

export default Close