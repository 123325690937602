import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import DashBoardStats from "../components/DashBoardStats";
import Close from "../components/Close";
import PieChart from "../charts/PieChart";
import { decryption } from "../Functions/Decrypted";

const Dashboard = ({
  setSettingScreen,
  setDashBoardScreen,
  getDashboardData,
  gameDashboardData,
  audio
}) => {
  const [dashboardEffect, setDashboardEffect] = useState(true);

  useEffect(() => {
    if (dashboardEffect) {
      return;
    }
    setTimeout(() => {
      setDashBoardScreen(false);
      setSettingScreen(true);
    }, 800);
  }, [dashboardEffect]);

  const handleClose = () => {
    audio.play();
    setDashboardEffect(false);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <div
      className="dashboard-container flex"
      style={{
        animation: dashboardEffect
          ? "fadeIn 1s ease-in-out"
          : "fadeOut 1s ease-in-out",
      }}
    >
      <h1 className="dashboard-heading">dashboard</h1>
      <div className="dashboard-stats-container flex">
        {/* /////// dashboard left side //////// */}
        <div className="dashboard-left-side flex">
          <DashBoardStats
            bgcolor={"#0342B1"}
            statValue={gameDashboardData.flipDone}
            statName={"flip done"}
          />
          <DashBoardStats
            bgcolor={"#50C9FF"}
            statValue={Number(gameDashboardData.mostWin).toFixed(2)}
            statName={"Most Won"}
          />
          <DashBoardStats
            bgcolor={"#CD21D8"}
            statValue={gameDashboardData.highestStreak}
            statName={"Won Streak"}
          />
        </div>

        {/* /////// dashboard right side //////// */}
        <div className="dashboard-right-side flex">
          <div className="pie-chart">
            <PieChart
              flipDone={Number(gameDashboardData.flipDone)}
              highestStreak={Number(gameDashboardData.highestStreak)}
              mostWin={Number(gameDashboardData.mostWin)}
            />
          </div>
        </div>
      </div>

      {/* /////// Close btn //////// */}
      <Close onClick={() => handleClose()} />
    </div>
  );
};

export default Dashboard;
