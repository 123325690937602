import { decryptionObj } from "../../Functions/Decrypted";
import axiosBaseURL, { URL } from "../axiosConfig";

export const getToken = async (token) => {
  try {
    const encryptResult = await axiosBaseURL.get("/getIngametoken", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (encryptResult.data.Token) {
      const decryptResult = decryptionObj(
        encryptResult.data.Token.encryptedData
      );
      // console.log("encryptResult",decryptResult)
      return decryptResult;
    }
    return false;
  } catch (error) {
    console.log("Error occur get Token api", error.message);
  }
};

// transaction history
export const getTransactionHistory = async (token) => {
  try {
    const encryptResult = await axiosBaseURL.get("/getTransaction_history", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (encryptResult.data.Success) {
      if (encryptResult.data.Transaction.length) {
        console.log(
          "encryptResult.data.Transaction;",
          encryptResult.data.Transaction
        );
        return encryptResult.data.Transaction;
      }
      return [];
    }
    return [];
  } catch (error) {
    console.log("Error occur transaction history", error.response);
    return [];
  }
};

// transaction history
export const getCommunityWalletAddress = async () => {
  try {
    const encryptResult = await axiosBaseURL.get(
      `${URL}/admin/get_companywallet`
    );
    if (encryptResult.data.Success) {
      const decryptResult = decryptionObj(
        encryptResult.data.Wallet.fromaddress
      );
      console.log(decryptResult)
      return decryptResult;
    }
  } catch (error) {
    console.log("Error on community wallet address", error.response);
  }
};
