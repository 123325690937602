import React from "react";
import { Chart } from "react-google-charts";

export default function PieChart({flipDone, highestStreak, mostWin}) {
   const data = [
    ["Task", "Hours per day"],
    ["Flip Done", flipDone],
    ["Most Won", mostWin],
    ["Won Streak", highestStreak],
  ];
   const options = {
    pieHole: 0.4,
    legend: 'none',
    colors: ["#0342B1", "#50C9FF", "#CD21D8"],
    backgroundColor: 'transparent',
  };
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}